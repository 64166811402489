<template>
  <div style="border-radius: 5px; width: 200px; padding: 10px">
    <div class="mb-2">
      <label> This filter will exclude the selected clients </label>
    </div>
    <div>
      <label>
        <input type="checkbox" value="Liquid Death" v-model="liquidDeathSelected" />
        Liquid Death
      </label>
    </div>
    <div>
      <label>
        <input
          type="checkbox"
          value="Sovereign Brands"
          v-model="sovereignBrandsSelected"
        />
        Sovereign Brands
      </label>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      liquidDeathSelected: true,
      sovereignBrandsSelected: true,
    };
  },
  computed: {
    selectedValues() {
      const values = [];
      if (this.liquidDeathSelected) values.push("Liquid Death");
      if (this.sovereignBrandsSelected) values.push("Sovereign Brands");
      return values;
    },
  },
  methods: {
    init(params) {
      this.params = params;
      this.liquidDeathSelected = true;
      this.sovereignBrandsSelected = true;
    },
    isFilterActive() {
      return this.selectedValues.length > 0;
    },
    doesFilterPass(params) {
      const value = this.params.valueGetter(params.node);
      if (this.selectedValues.length === 0) {
        return !["Liquid Death", "Sovereign Brands"].includes(value);
      } else {
        return this.selectedValues.includes(value);
      }
    },
    getModel() {
      if (!this.isFilterActive()) {
        return null;
      }
      return {
        filter: this.selectedValues,
        type: "exclude",
        filterType: "text",
      };
    },
    setModel(model) {
      this.liquidDeathSelected = model ? model.filter.includes("Liquid Death") : false;
      this.sovereignBrandsSelected = model
        ? model.filter.includes("Sovereign Brands")
        : false;
    },
  },
  watch: {
    liquidDeathSelected(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.params.filterChangedCallback();
      }
    },
    sovereignBrandsSelected(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.params.filterChangedCallback();
      }
    },
  },
};
</script>
